<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio prodotto</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="product_container">
                    <div class="product_title">
                        {{ product.fw_products_name }}
                    </div>
                    <div v-if="product.fw_products_main_image">
                        <img :src="generateLink(product.fw_products_main_image)" alt="" style="max-height: 350px;" />
                    </div>
                    <div class="field field_document">
                        <div class="title">Documenti</div>
                        <div v-if="documents.length != 0" class="document_container">
                            <a
                                v-for="(doc, index) in documents"
                                :key="index"
                                class="single_document"
                                :href="generateLink(doc.fw_product_documents_document)"
                                target="_blank"
                                download
                            >
                                <ion-icon :icon="documentAttach"></ion-icon>
                                <div class="document_name">
                                    {{ doc.fw_product_documents_description }}
                                </div>
                            </a>
                        </div>
                        <div v-else class="document_name">
                            Nessun documento caricato
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    //IonSpinner,
    modalController,
} from "@ionic/vue";
import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";

import { arrowBackOutline, arrowForwardCircle, documentAttach } from "ionicons/icons";

import { openToast } from "@/services/toast";

import apiProdotti from "@/services/prodotti";

export default defineComponent({
    name: "DettaglioProdotto",
    props: {
        prodotto: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        //IonSpinner,
    },
    setup(props, context) {
        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const loading = ref(false);
        const successResponse = ref(false);

        const product = ref([]);
        product.value = { ...props.prodotto };

        /*******************************************************
         *
         * ! Handle close modal by clicking on Chiudi button
         *
         ******************************************************/
        const closeModal = () => {
            modalController.dismiss();
        };

        function generateLink(file) {
            if (file) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${file}`;
            }
        }

        /*******************************************************
         *
         * ! Mostra servizi associati alla commessa dell'appuntamento visualizzato
         *
         ******************************************************/

        const loadingDocuments = ref(false);
        const documents = ref([]);
        async function loadServiziCommessa() {
            loadingDocuments.value = true;

            try {
                const res = await apiProdotti.getProductDocument(product.value.project_products_product);
                if (res.status === 0) {
                    documents.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei documenti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei documenti", "toast_danger");
            } finally {
                //console.log(customers.value);
                loadingDocuments.value = false;
            }
        }

        onMounted(() => {
            loadServiziCommessa();
        });

        return {
            loading,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            product,
            generateLink,
            documents,
            loadingDocuments,
            documentAttach,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

/* lead v2*/
.product_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    /*margin-bottom: 10px;*/
    margin-bottom: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.product_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.product_container .field_document {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.product_container .field_document .title {
    width: 100%;
    margin-bottom: 16px;
}
.product_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 6px;
    border-bottom: none;
    margin-bottom: 0;
}
.product_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}

.fontawesome_icon {
    color: #086fa3;
    color: #475569;
    font-size: 14px;
}

.product_container .product_title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}

.single_servizio,
.single_prodotto {
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}

.document_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
}
.single_document {
    width: 100%;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}
.single_document ion-icon {
    color: #086fa3;
    font-size: 18px;
}
.document_name {
    color: #111111;
    font-size: 14px;
    font-weight: 500;
}
</style>
