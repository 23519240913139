<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio programma</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- QRCode modal -->
                <Transition name="fade-transition">
                    <div v-if="showQrCode" class="modal_straordinario">
                        <div class="input_container">
                            <div class="title_qr">Inquadra il codice QR per timbrare</div>
                            <div class="qr_code">
                                <qr-stream @decode="onDecode" class="mb">
                                    <div class="frame"></div>
                                </qr-stream>
                            </div>

                            <div class="confirmation_text">
                                {{ confirmationText }}
                            </div>

                            <button @click="handleQrCodeTimbratura()" class="btn_scan_qrcode" :disabled="disabledQrCodeButton">Salva</button>
                            <button @click="chiudiQrCode()" class="btn_close_scan_qrcode">Chiudi</button>
                        </div>
                    </div>
                </Transition>

                <!-- NFC modal -->
                <Transition name="fade-transition">
                    <div v-if="showNfcContainer" class="modal_nfc">
                        <div class="nfc_container">
                            <div class="title_nfc">Pronto per la scansione</div>
                            <div class="svg_container">
                                <img src="assets/nfc_icon.svg" alt="" />
                            </div>

                            <div class="help_text">
                                Avvicina il Tag NFC
                            </div>
                            <button @click="chiudiNfcContainer()" class="btn_chiudi_nfc">Annulla</button>
                        </div>
                    </div>
                </Transition>

                <div class="appuntamento_container">
                    <div class="appuntamento_title">
                        {{ appuntamento.projects_name }}
                    </div>
                    <div class="appuntamento_cliente">
                        {{ riferimentoCliente(appuntamento) }}
                    </div>
                    <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormat(appuntamento.appuntamenti_giorno) }},
                            {{ `${appuntamento.appuntamenti_ora_inizio} -  ${appuntamento.appuntamenti_ora_fine}` }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            <a
                                :href="
                                    `https://maps.google.com/?q=${appuntamento.projects_city} ${appuntamento.projects_address} ${appuntamento.projects_province}`
                                "
                                target="_blank"
                                class="mobile_link"
                            >
                                <span>{{ appuntamento.projects_address }}</span>
                                <span>{{ appuntamento.projects_city }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="field field_list">
                        <div class="persone" v-for="(persona, index) in appuntamento.appuntamenti_persone" :key="index">
                            <font-awesome-icon :icon="['fas', 'user']" class="fontawesome_icon" />
                            <div>{{ persona }}</div>
                        </div>
                        <div class="automezzi" v-for="(automezzo, index) in appuntamento.appuntamenti_automezzi" :key="index">
                            <font-awesome-icon :icon="['fas', 'car']" class="fontawesome_icon" />
                            <div>{{ automezzo }}</div>
                        </div>
                    </div>
                    <div v-if="appuntamento.appuntamenti_note" class="field field_description">
                        <div class="title">Note</div>
                        <div class="value">
                            {{ appuntamento.appuntamenti_note }}
                        </div>
                    </div>
                </div>

                <div class="appuntamento_container">
                    <div class="servizi_title">
                        Servizi da svolgere
                    </div>
                    <div v-if="loadingServices" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else class="servizi_container">
                        <div v-if="services && services.length != 0">
                            <div v-for="(service, index) in services" :key="index" class="single_servizio">
                                {{ service }}
                            </div>
                        </div>
                        <div v-else class="field">
                            <div class="title">
                                Nessun servizio disponibile
                            </div>
                        </div>
                    </div>
                </div>

                <div class="appuntamento_container">
                    <div class="servizi_title">
                        Prodotti associati
                    </div>
                    <div v-if="loadingServices" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else class="servizi_container">
                        <div v-if="products && products.length != 0">
                            <div v-for="(product, index) in products" :key="index" class="single_prodotto" @click="openProductDetail(product)">
                                <span>{{ product.fw_products_name }}</span>
                                <span>{{ `(${product.project_products_quantity})` }}</span>
                            </div>
                        </div>
                        <div v-else class="field">
                            <div class="title">
                                Nessun prodotto associato
                            </div>
                        </div>
                    </div>
                </div>

                <div class="appuntamento_container">
                    <div class="servizi_title">
                        Capitolato
                    </div>
                    <div v-if="loadingCapitolato" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else class="servizi_container">
                        <div v-if="documents && documents.length != 0">
                            <div v-for="(document, index) in documents" :key="index" class="single_document">
                                <span clas="document_title">{{ document.project_attachments_title }}</span>
                                <div v-if="document.project_attachments_file" class="single_file">
                                    <a
                                        v-for="(file, index) in JSON.parse(document.project_attachments_file)"
                                        :key="index"
                                        :href="generateLink(file.path_local)"
                                        target="_blank"
                                        download
                                    >
                                        <ion-icon :icon="documentAttach" class="document_preview"></ion-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-else class="field">
                            <div class="title">
                                Nessun documento da visualizzare
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div v-if="warning">
                    <div class="warning_container">
                        <div>Attenzione</div>
                        <div>Non hai compilato il rapportino dopo aver timbrato l'uscita</div>
                    </div>
                </div> -->

                <div class="container_btns" v-if="validAppointmentDate && !rapportinoCreato">
                    <div v-if="appuntamento.projects_richiedi_timbratura && appuntamento.projects_richiedi_timbratura == '1'" class="btn_timbratura_container">
                        <button
                            v-if="appuntamento.projects_timbra_nfc && appuntamento.projects_timbra_nfc == '1'"
                            class="btn_conferma_entrata"
                            @click="nfcScan()"
                        >
                            Timbra con NFC
                        </button>
                        <button
                            v-if="appuntamento.projects_timbra_gps && appuntamento.projects_timbra_gps == '1'"
                            class="btn_conferma_entrata"
                            @click="setEntrata()"
                        >
                            Timbra con GPS
                        </button>
                    </div>
                    <div v-if="appuntamento.projects_richiedi_timbratura && appuntamento.projects_richiedi_timbratura == '1'" class="btn_timbratura_container">
                        <button
                            v-if="appuntamento.projects_timbra_qrcode && appuntamento.projects_timbra_qrcode == '1'"
                            class="btn_conferma_entrata"
                            @click="openQrCode()"
                        >
                            Timbra con QR
                        </button>
                    </div>
                    <div
                        v-if="appuntamento.projects_richiedi_timbratura && appuntamento.projects_richiedi_timbratura == '0' && !rapportinoCreato"
                        class="btn_nuovo_rapportino"
                        @click="openNuovoRapportino()"
                    >
                        Crea rapportino
                    </div>
                </div>
                <div
                    v-if="appuntamento.projects_permetti_ordine_materiali && appuntamento.projects_permetti_ordine_materiali == '1'"
                    class="btn_ordina_materiali"
                    @click="openOrdinaMateriali()"
                >
                    Ordina materiali
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonSpinner,
    modalController,
} from "@ionic/vue";
import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";

import { arrowBackOutline, arrowForwardCircle, documentAttach } from "ionicons/icons";

import moment from "moment";
//Lettura QR
import { QrStream } from "vue3-qr-reader";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiPresenze from "@/services/presenze";
import apiCommesse from "@/services/commesse";
import apiProdotti from "@/services/prodotti";
import apiRapportini from "@/services/rapportini";

import NuovoRapportinoAppuntamento from "@/components/rapportini/NuovoRapportinoAppuntamento.vue";
import NuovoRapportinoPresenza from "@/components/rapportini/NuovoRapportinoPresenza.vue";
import OrdineMaterialeAppuntamento from "@/components/ordini/OrdineMaterialeAppuntamento.vue";

import DettaglioProdotto from "@/components/programma_lavoro/DettaglioProdotto.vue";

export default defineComponent({
    name: "ProgrammaLavoroDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonSpinner,
        QrStream,
    },
    setup(props, context) {
        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const loading = ref(false);
        const successResponse = ref(false);

        const appuntamento = ref([]);
        appuntamento.value = { ...props.data };

        const presenza = ref(null);

        const rapportinoCreato = ref(false);

        /*******************************************************
         *
         * ! Open modal to display product info
         *
         ******************************************************/
        async function openProductDetail(product) {
            const modal = await modalController.create({
                component: DettaglioProdotto,
                componentProps: {
                    prodotto: product,
                },
            });
            return modal.present();
        }

        /*******************************************************
         *
         * ! Open modal to create new intervento
         *
         ******************************************************/
        async function openNuovoInterventoFromPresenza(presenza, appuntamento) {
            let presenza_rapportino = {
                presenza: presenza,
                appuntamento: appuntamento,
            };

            localStorage.setItem("presenza_rapportino", JSON.stringify(presenza_rapportino));

            const modal = await modalController.create({
                component: NuovoRapportinoPresenza,
                componentProps: {
                    data: presenza,
                    app: appuntamento,
                },
            });

            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    openToast("Rapportino salvato con successo", "toast_success");
                    //svuoto presenza_rapportino
                    presenza_rapportino = null;
                    //Nascondo pulsanti
                    rapportinoCreato.value = true;
                }
            });

            return modal.present();
        }

        /*****************************************************
         *
         * ! QR Code handler
         *
         *****************************************************/
        /**
         * ! CONTROLLO presenze
         * * Se trovo almeno una presenza di oggi senza data fine vuol dire che sto timbrando l'uscita (posso averne fatte altre oggi ma sempre entrando ed uscendo)
         * * Se non la trovo vuol dire che sto timbrando l'entrata (o è la prima/unica della giornata o sto entrando nuovamente)
         */
        const confirmationText = ref("");
        const state = reactive({
            data: null,
        });
        const showQrCode = ref(false);
        const disabledQrCodeButton = ref(false);

        function onDecode(data) {
            state.data = data;
            if (data) {
                const time = moment().format("HH:mm");
                confirmationText.value = `Cliccando su "Salva" confermi di timbrare alle ore ${time}`;
            }
        }

        function openQrCode() {
            showQrCode.value = true;
        }
        function chiudiQrCode() {
            showQrCode.value = false;
            state.data = null;
            confirmationText.value = "";
            disabledQrCodeButton.value = false;
        }
        async function handleQrCodeTimbratura() {
            const cliente = appuntamento.value.appuntamenti_cliente;
            const commessa = appuntamento.value.appuntamenti_impianto;

            if (state.data) {
                let qrValue = state.data;
                disabledQrCodeButton.value = true;

                try {
                    const response = await apiPresenze.scanQrCode(qrValue, dipendenteID, cliente, commessa);
                    if (response.data.status === 0) {
                        //error
                        openToast(response.data.txt, "toast_danger");
                    } else {
                        //Entrata o uscita timbrata correttamente
                        openToast(response.data.txt, "toast_success");
                        //Sto uscendo, devo aprire modale per creare il rapportino con i dati della presenza
                        if (response.data.tipo === "uscita") {
                            presenza.value = response.data.data;
                            openNuovoInterventoFromPresenza({ ...presenza.value }, { ...appuntamento.value });
                        }
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Errore durante la scansione del codice QR", "toast_danger");
                } finally {
                    state.data = null;
                    showQrCode.value = false;
                    qrValue = null;
                    confirmationText.value = "";
                    disabledQrCodeButton.value = false;
                }
            } else {
                openToast("Inquadra il codice QR prima di salvare la presenza", "toast_danger");
            }
        }

        /*****************************************************
         *
         * ! Gestisce scansione con NFC per timbrare entrata o uscita
         *
         *****************************************************/
        const scannedNFCValue = ref(null);
        const blockScan = ref(false);

        const showNfcContainer = ref(false);
        function chiudiNfcContainer() {
            scannedNFCValue.value = null;
            blockScan.value = true;
            showNfcContainer.value = false;
        }

        async function handleTimbraturaNfc(reparto_id) {
            blockScan.value = true;

            const cliente = appuntamento.value.appuntamenti_cliente;
            const commessa = appuntamento.value.appuntamenti_impianto;

            apiPresenze
                .timbraNFC(dipendenteID, reparto_id, cliente, commessa)
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        //alert(JSON.stringify(response));
                        if (response.data.tipo === "entrata") {
                            //Inserisco nuova presenza --> TIMBRO INGRESSO
                            chiudiNfcContainer();
                        } else {
                            //Chiudo presenza e apro modale per inserimento rapportino --> TIMBRO USCITA
                            chiudiNfcContainer();
                            presenza.value = response.data.data;
                            openNuovoInterventoFromPresenza(presenza.value, appuntamento.value);
                        }
                        openToast(response.data.txt, "toast_success");
                        //blockScan.value = true;
                        scannedNFCValue.value = null;
                    }
                })
                .catch((error) => {
                    //blockScan.value = true;
                    console.error(error);
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }

        const nfcScan = async () => {
            if (!appuntamento.value.projects_reparto) {
                openToast("La commessa non è associata ad alcun reparto, contatta l'assistenza per informazioni", "toast_danger");
                return;
            }

            blockScan.value = false;
            showNfcContainer.value = true;

            try {
                // eslint-disable-line
                const ndef = new NDEFReader(); // eslint-disable-line
                await ndef.scan();
                ndef.addEventListener("reading", ({ message }) => {
                    const decoder = new TextDecoder();

                    for (const record of message.records) {
                        switch (record.recordType) {
                            case "text": {
                                const textDecoder = new TextDecoder(record.encoding);
                                scannedNFCValue.value = textDecoder.decode(record.data);
                                //Se ho scansionato qualcosa e non sono in blocco procedo con la timbratura
                                if (scannedNFCValue.value && !blockScan.value) {
                                    handleTimbraturaNfc(scannedNFCValue.value);
                                }
                                break;
                            }
                            default:
                                openToast("Impossibile effettuare la scansione", "toast_danger");
                        }
                    }
                });
            } catch (error) {
                openToast("La scansione con NFC non è supportata", "toast_danger");
                console.log(error);
                showNfcContainer.value = false;
                blockScan.value = false;
            }
        };

        /*******************************************************
         *
         * ! Open modal to order materiali
         *
         ******************************************************/
        async function openOrdinaMateriali() {
            const modal = await modalController.create({
                component: OrdineMaterialeAppuntamento,
                componentProps: {
                    data: appuntamento.value,
                },
            });

            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data != undefined) {
                    openToast(detail.data.txt, "toast_success");
                }
            });

            return modal.present();
        }

        /**
         * ! Controlla se il'appuntamento visualizzato è legato ad un rapportino, se sì nasconde btn per creare rapportino
         */
        async function rapportinoCreated() {
            try {
                const res = await apiRapportini.getRapportinoAppuntamento(appuntamento.value.appuntamenti_id, userID);
                if (res.status === 0) {
                    if (res.data.length != 0) {
                        rapportinoCreato.value = true;
                    }
                } else {
                    openToast("Errore durante il controllo sui rapportini", "toast_danger");
                    return false;
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante il controllo sui rapportini", "toast_danger");
                return false;
            }
        }

        /*******************************************************
         *
         * ! Open modal to create new intervento
         *
         ******************************************************/
        async function openNuovoRapportino() {
            const modal = await modalController.create({
                component: NuovoRapportinoAppuntamento,
                componentProps: {
                    data: appuntamento.value,
                },
            });

            modal.onDidDismiss().then((detail) => {
                console.log(detail);
                if (detail.data != undefined) {
                    rapportinoCreato.value = true;
                    openToast("Rapportino salvato con successo", "toast_success");
                }
            });

            return modal.present();
        }

        /*******************************************************
         *
         * ! Handle close modal by clicking on Chiudi button
         *
         ******************************************************/
        const closeModal = () => {
            modalController.dismiss();
        };
        /*******************************************************
         *
         * ! Close modal after form data submit
         *
         ******************************************************/
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /*******************************************************
         *
         * ! Print correct customer info
         *
         ******************************************************/
        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }

            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        function createMapsLink(appuntamento) {
            const address = `${appuntamento.projects_address}, ${appuntamento.projects_city}`;
            const trimmedString = address.length > 42 ? address.substring(0, 39) + "..." : address;
            return trimmedString;
        }

        function setEntrata() {
            setTimeout(() => {
                openToast("Rilevamento effettuato con successo", "toast_success");
            }, 700);
        }

        /*******************************************************
         *
         * ! Controllo se data appuntamento è > oggi, se lo è nascondo tutti i pulsanti
         *
         ******************************************************/
        const validAppointmentDate = ref(false);
        function checkDate() {
            const todayDate = new moment(new Date()).format("YYYY-MM-DD");
            const appointmentDate = moment(appuntamento.value.appuntamenti_giorno).format("YYYY-MM-DD");

            if (todayDate === appointmentDate) {
                validAppointmentDate.value = true;
            }
        }

        function generateLink(file) {
            if (file) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${file}`;
            }
        }

        /*******************************************************
         *
         * ! Mostra servizi associati alla commessa dell'appuntamento visualizzato
         *
         ******************************************************/

        const loadingServices = ref(false);
        const services = ref([]);
        async function loadServiziCommessa() {
            loadingServices.value = true;

            try {
                const res = await apiCommesse.getCommessaServizi(appuntamento.value.appuntamenti_impianto);

                if (res.status === 0) {
                    services.value = res.data.projects_services;
                } else {
                    openToast("Errore durante la richiesta dei servizi relativi alla commessa", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei servizi relativi alla commessa", "toast_danger");
            } finally {
                //console.log(customers.value);
                loadingServices.value = false;
            }
        }

        /*******************************************************
         *
         * ! Mostra prodotti associati alla commessa dell'appuntamento visualizzato
         *
         ******************************************************/
        const products = ref([]);
        async function loadProducts() {
            try {
                const res = await apiProdotti.getProdotti(appuntamento.value.appuntamenti_impianto);
                //const res = await apiProdotti.getProdotti();
                if (res.status === 0) {
                    products.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei prodotti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei prodotti", "toast_danger");
            } finally {
                //console.log(customers.value);
            }
        }

        /*******************************************************
         *
         * ! Mostra prodotti associati alla commessa dell'appuntamento visualizzato
         *
         ******************************************************/
        const documents = ref([]);
        const loadingCapitolato = ref(false);
        async function loadProjectDocuments() {
            loadingCapitolato.value = true;
            try {
                const res = await apiCommesse.getProjectDocuments(appuntamento.value.appuntamenti_impianto);
                if (res.status === 0) {
                    documents.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei documenti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei documenti", "toast_danger");
            } finally {
                //console.log(customers.value);
                loadingCapitolato.value = false;
            }
        }

        /*********************************************************
         *
         * ! Controllo se ho record nel localstorage, se sì vuol dire che non ho compilato subito il rapportino collegato
         * ! Se il record è per questo appuntamento allora devo aprire la modale per compilare il rapportino
         *
         ********************************************************/
        const warning = ref(true);
        function getLocalPresenza() {
            const presenza_rapportino = JSON.parse(localStorage.getItem("presenza_rapportino"));

            if (presenza_rapportino && Object.keys(presenza_rapportino).length !== 0) {
                if (appuntamento.value.appuntamenti_id === presenza_rapportino.appuntamento.appuntamenti_id) {
                    //Ho presenza chiusa ma non ho compilato il rapportino collegato perchè altrimenti avrei svuotato il dato nel localstorage
                    //Devo comunque nascondere i pulsanti perchè finchè non creo il rapportino dalla chiusura presenza la pagina continauerà ad aprirsi (giustamente)
                    rapportinoCreato.value = true;
                    openNuovoInterventoFromPresenza(presenza_rapportino.presenza, presenza_rapportino.appuntamento);
                }
            }
        }

        onMounted(() => {
            rapportinoCreated();
            checkDate();
            loadServiziCommessa();
            loadProducts();
            loadProjectDocuments();
            getLocalPresenza();
        });

        return {
            loading,
            dateFormat,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            appuntamento,
            openNuovoRapportino,
            riferimentoCliente,
            openOrdinaMateriali,
            createMapsLink,
            setEntrata,
            //NFC
            nfcScan,
            showNfcContainer,
            chiudiNfcContainer,
            //QR Code
            openQrCode,
            showQrCode,
            QrStream,
            ...toRefs(state),
            onDecode,
            chiudiQrCode,
            handleQrCodeTimbratura,
            confirmationText,
            disabledQrCodeButton,
            //Show/Hide button
            validAppointmentDate,
            //Services & products
            loadingServices,
            services,
            products,
            warning,
            rapportinoCreato,
            //Dettaglio prodotto
            openProductDetail,
            //Elenco documento capitolato
            documents,
            generateLink,
            documentAttach,
            loadingCapitolato,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

/* lead v2*/
.appuntamento_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    /*margin-bottom: 10px;*/
    margin-bottom: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.appuntamento_container .servizi_title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.appuntamento_container .appuntamento_title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.appuntamento_container .appuntamento_cliente {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.appuntamento_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.appuntamento_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.appuntamento_container .field_list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}

.appuntamento_container .field_list:last-of-type {
    margin-bottom: 0px;
}
.appuntamento_container .field_list .title {
    margin-bottom: 4px;
}
.appuntamento_container .field_list .persone,
.appuntamento_container .field_list .automezzi {
    display: flex;
    gap: 8px;
    font-size: 14px;
    margin-bottom: 8px;
}
.appuntamento_container .field_description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.appuntamento_container .field_description .title {
    width: 100%;
    margin-bottom: 4px;
}
.appuntamento_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 6px;
    border-bottom: none;
    margin-bottom: 0;
}
.appuntamento_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.container_btns {
    width: 100%;
    margin-top: 32px;
}

.btn_conferma_entrata {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    color: #ffffff;
    background-color: #086fa3;
}
.btn_conferma_entrata:focus {
    background-color: #044768;
    box-shadow: 0px 2px 6px 2px rgba(58, 58, 58, 0.3);
}
.btn_conferma_entrata:disabled {
    opacity: 0.5;
}
.btn_ordina_materiali {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    color: #ffffff;
    background-color: #06b6d4;
    margin: 24px 0;
}
.btn_nuovo_rapportino {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    color: #ffffff;
    background-color: #086fa3;
}

.mobile_link {
    font-weight: 500;
    text-decoration: none;
    color: #086fa3;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.btn_timbratura_container {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
}

/* Modal NFC Tag */

.modal_nfc {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 16px;
    z-index: 10;
}
.nfc_container {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
}
.nfc_container .field {
    margin-bottom: 24px;
}
.nfc_container .title {
    font-weight: 500;
    margin-bottom: 12px;
}
.nfc_container .title_nfc {
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
}
.nfc_container .help_text {
    color: #6b7280;
    text-align: center;
    margin-bottom: 16px;
}
.svg_container {
    padding: 0 52px;
    margin: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.svg_container svg {
    max-width: 100px;
}
.btn_chiudi_nfc {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: rgb(220 38 38);
    background-color: rgb(254 202 202);
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    margin-top: 12px;
}

.appuntamento_container .servizi_container {
    width: 100%;
}
.fontawesome_icon {
    color: #086fa3;
    color: #475569;
    font-size: 14px;
}

.single_servizio,
.single_prodotto {
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    padding: 2px;
}

.single_document {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    padding: 6px;
    flex-direction: column;
}

/** MODALE QR CODE */

.modal_straordinario {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 16px;
    z-index: 10;
}
.input_container {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
}
.input_container .field {
    margin-bottom: 24px;
}
.input_container .title {
    font-weight: 500;
    margin-bottom: 12px;
}
.input_container .title_qr {
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
}
.input_container .confirmation_text {
    margin-top: 16px;
    font-weight: bold;
}

.btn_scan_qrcode {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #0a90d4;
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    margin-top: 24px;
}
.btn_scan_qrcode:disabled {
    /* background-color: rgba(10, 144, 212, 0.5);
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1); */
    opacity: 0.75;
    transition: all 0.15s ease-in;
}
.btn_close_scan_qrcode {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: rgb(220 38 38);
    background-color: rgb(254 202 202);
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    margin-top: 12px;
}
.warning {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.single_file {
    margin-bottom: 4px;
}
.single_file a {
    display: inline-block;
    padding: 6px 12px;
}
.document_preview {
    color: #086fa3;
    font-size: 32px;
}
</style>
