
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, menuController, IonButtons } from "@ionic/vue";
import { add, refresh, menu, checkmarkCircle } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { Calendar } from "v-calendar";
import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";

import apiAppuntamenti from "@/services/appuntamenti_squadre";
import apiAppuntamentiCustom from "@/services/appuntamenti";

import ProgrammaLavoroDetail from "@/components/programma_lavoro/ProgrammaLavoroDetail.vue";
import moment from "moment";
import rapportini from "@/services/rapportini";

export default {
    name: "ProgrammaLavoro",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        Calendar,
    },
    setup() {
        //const icons = ["broom", "tree", "circle-radiation", "soap"];
        const icons = ["broom", "broom", "circle-radiation", "circle-radiation", "circle-radiation", "broom"];
        const base_icon = "calendar";
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;

        const appuntamentiToShow = ref([]);
        const calendarRef = ref(null);

        const openMenu = () => {
            menuController.open("app-menu");
        };

        function setIcon(appuntamento) {
            if (appuntamento.projects_type) {
                if (icons[appuntamento.projects_type - 1]) {
                    return icons[appuntamento.projects_type - 1];
                }
                return base_icon;
            } else {
                return base_icon;
            }
        }

        async function controlloAppuntamenti(appuntamentiVisualizzati) {
            if (appuntamentiVisualizzati.length != 0) {
                //Estraggo gli id degli appuntamenti e cerco i rapportini per questi appuntamenti_id
                const appuntamentiId = [];
                appuntamentiVisualizzati.forEach((el) => {
                    appuntamentiId.push(el.appuntamenti_id);
                });

                if (appuntamentiId.length != 0) {
                    const ids = `(${appuntamentiId.toString()})`;

                    try {
                        const res = await apiAppuntamentiCustom.getRapportiniAppuntamenti(ids, userID);
                        if (res.status === 0) {
                            if (res.data.length != 0) {
                                appuntamentiVisualizzati.forEach((appuntamento) => {
                                    appuntamento.rapportino_creato = false;
                                    //Se l'appuntamento è legato ad un rapportino lo marco con rapportino_creato
                                    const appuntamentoId = appuntamento.appuntamenti_id;
                                    const hasRapportino = res.data.some((rapportino) => rapportino.rapportini_appuntamento_id === appuntamentoId);

                                    if (hasRapportino) {
                                        appuntamento.rapportino_creato = true;
                                    } else {
                                        appuntamento.rapportino_creato = false;
                                    }
                                });
                            } else {
                                appuntamentiVisualizzati.forEach((appuntamento) => {
                                    appuntamento.rapportino_creato = false;
                                });
                            }
                        } else {
                            openToast("Errore durante il controllo degli appuntamenti", "toast_danger");
                        }
                    } catch (error) {
                        console.log(error);
                        openToast("Errore durante il controllo degli appuntamenti", "toast_danger");
                    }
                }
            }
        }

        /**
         * ! Passing appuntamento object ProgrammaLavoroDetail page
         */
        async function openDetailModal(appuntamento) {
            const modal = await modalController.create({
                component: ProgrammaLavoroDetail,
                componentProps: {
                    data: appuntamento,
                },
            });
            modal.onDidDismiss().then((detail) => {
                controlloAppuntamenti(appuntamentiToShow.value);
            });
            return modal.present();
        }

        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        const indirizzoCliente = (cliente) => {
            const city = cliente.customers_city ? cliente.customers_city : "";
            const province = cliente.customers_province ? "(" + cliente.customers_province + ")" : "";
            return `${city} ${province}`;
        };

        /**
         * ! Get all appuntamenti
         */
        const appuntamenti = ref([]);
        async function loadAppuntamenti() {
            loading.value = true;
            try {
                const res = await apiAppuntamenti.getAppuntamenti(userID);
                if (res.status === 0) {
                    appuntamenti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
                }
            } catch (error) {
                appuntamenti.value = [];
                openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! Set calendar data
         */
        const date = ref(new Date());
        const selectedDay = ref(new Date()); // Add state to store selected day

        const setAttributes = computed(() => {
            return appuntamenti.value.map((appuntamento) => ({
                key: `appuntamento.${appuntamento.appuntamenti_id}`,
                highlight: {
                    color: "blue",
                    fillMode: "solid",
                },
                dates: appuntamento.appuntamenti_giorno,
                customData: appuntamento,
            }));
        });

        /**
         * ! Mostra solo gli appuntamenti del giorno selezioanto
         */
        const dayClicked = (day) => {
            //console.log(day);
            selectedDay.value = day;
            appuntamentiToShow.value = [];
            appuntamentiToShow.value = day.attributes.length == 0 ? [] : day.attributes.map((appuntamento) => appuntamento.customData);
            //controllo se gli appuntamenti di oggi hanno già un rapportino creato per mostrare spunta
            if (appuntamentiToShow.value.length != 0) {
                controlloAppuntamenti(appuntamentiToShow.value);
            }
        };

        const todayAppointments = ref([]);
        async function loadData(startDate: string, endDate: string) {
            appuntamentiToShow.value = [];
            //console.log(startDate, endDate);
            try {
                const res = await apiAppuntamentiCustom.getAppuntamenti(userID, startDate, endDate);
                if (res.status === 0) {
                    appuntamenti.value = res.data;
                    //Popolo array degli appuntamenti da mostrare con quelli di oggi per vederli subito
                    const todayApp = res.data.filter((el) => {
                        if (moment(el.appuntamenti_giorno).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD")) {
                            return el;
                        }
                    });
                    appuntamentiToShow.value = todayApp;
                    controlloAppuntamenti(appuntamentiToShow.value);
                } else {
                    openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
                }
            } catch (error) {
                appuntamenti.value = [];
                openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! GET CURRENT MONTH APPOINTMENTS
         * @param page current month and yer object
         *
         */
        const loadCurrentMonthData = (page) => {
            const currentMonth = page.month;
            const currentYear = page.year;

            const momentStartDate = moment([currentYear, currentMonth - 1]);
            const momentEndDate = moment(momentStartDate).endOf("month");
            const formatStart = momentStartDate.format("YYYY-MM-DD");
            const formatEnd = momentEndDate.format("YYYY-MM-DD");

            loadData(formatStart, formatEnd);
        };

        const moveDate = async () => {
            await calendarRef.value.move(`1983-01-21`);
            await calendarRef.value.focusDate(`1983-01-21`, {
                transition: "slide-h",
                position: 1,
            });
        };

        const selectToday = () => {
            const today = moment(new Date()).format("YYYY-MM-DD");
            console.log(today);
            const todayCalendarEl: HTMLElement = document.querySelector(`.id-${today}`);
            console.log(todayCalendarEl);
            todayCalendarEl.click();
        };

        onMounted(() => {
            //GET APPUNTAMENTI DATA
            /*loadAppuntamenti();
            console.log(calendarRef);
            //moveDate()todayCalendarEl
            selectToday();*/
            //selectToday();
        });

        return {
            loading,
            add,
            refresh,
            openDetailModal,
            menu,
            openMenu,
            //Appuntamenti
            loadAppuntamenti,
            dateFormat,
            appuntamenti,
            Calendar,
            date,
            selectedDay,
            dayClicked,
            setAttributes,
            appuntamentiToShow,
            riferimentoCliente,
            indirizzoCliente,
            checkmarkCircle,
            icons,
            setIcon,
            loadCurrentMonthData,
            calendarRef,
            todayAppointments,
        };
    },
};
